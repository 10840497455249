import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
		store_uid:'',
        buttons: [],
        keepAlive: [],
        menu: []
    },
    getters: {
        article_type() {
            // 1升学规划 2心理辅导 3出国留学4官方政策
            return {
                1: {
                    label: "升学规划",
                    value: 1,
                    type: "primary"
                },
                2: {
                    label: "心理辅导",
                    value: 2,
                    type: "success"
                },
                3: {
                    label: "出国留学",
                    value: 3,
                    type: "info"
                },
                4: {
                    label: "官方政策",
                    value: 4,
                    type: "warning"
                },
                5: {
                    label: "志愿填报",
                    value: 5,
                    type: ''
                }
            }
        },
        school_type() {
            //1公办2民办
            return {
                1: {
                    label: "公办",
                    value: 1,
                    type: "primary"
                },
                2: {
                    label: "民办",
                    value: 2,
                    type: "success"
                }
            }
        },
        school_tag_type() {
            //1学校标签2招生类型3班级类型4考生类型
            return {
                1: {
                    label: "学校标签",
                    value: 1,
                    type: "primary"
                },
                2: {
                    label: "招生类型",
                    value: 2,
                    type: "success"
                },
                3: {
                    label: "班级类型",
                    value: 3,
                    type: "info"
                },
                4: {
                    label: "考生类型",
                    value: 4,
                    type: "warning"
                }
            }
        },
        score_type() {
            //1往年平均分2末尾考生志愿编号3往届分数4录取最低分数
            return {
                1: {
                    label: "往年平均分",
                    value: 1,
                    type: "primary"
                },
                2: {
                    label: "末尾考生志愿编号",
                    value: 2,
                    type: "success"
                },
                3: {
                    label: "往届分数",
                    value: 3,
                    type: "info"
                },
                4: {
                    label: "录取最低分数",
                    value: 4,
                    type: "warning"
                }
            }
        },
        identity_type() {
            //1户籍2非户籍3外区生
            return {
                1: {
                    label: "户籍",
                    value: 1,
                    type: "primary"
                },
                2: {
                    label: "非户籍",
                    value: 2,
                    type: "success"
                },
                3: {
                    label: "外区生",
                    value: 3,
                    type: "info"
                }
            }
        },
        goods_source() {
            return {
                1: { label: "实物商品", value: 1, type: 'primary' },
                2: { label: "虚拟商品", value: 2, type: 'warning' },
            }
        },
        banner_type() {
            //1首页轮播图2直播讲座轮播图
            return {
                1: {
                    label: "首页轮播图",
                    value: 1,
                    type: "primary"
                },
                2: {
                    label: "直播讲座轮播图",
                    value: 2,
                    type: "success"
                }
            }
        },
        scenic_order_status() {
            //0待支付1已支付2售后中3已退款4已取消5待评价6已完成
            return {
                0: {
                    label: "待支付",
                    value: 0,
                    type: "info"
                },
                1: {
                    label: "已支付",
                    value: 1,
                    type: "success"
                },
                2: {
                    label: "售后中",
                    value: 2,
                    type: "warning"
                },
                3: {
                    label: "已退款",
                    value: 3,
                    type: "danger"
                },
                4: {
                    label: "已取消",
                    value: 4,
                    type: "danger"
                },
                5: {
                    label: "待评价",
                    value: 5,
                    type: "warning"
                },
                6: {
                    label: "已完成",
                    value: 6,
                    type: "success"
                }
            }
        },
        scenic_refund_status() {
            //0待退款1已退款2拒绝退款
            return {
                0: {
                    label: "待退款",
                    value: 0,
                    type: "info"
                },
                1: {
                    label: "已退款",
                    value: 1,
                    type: "success"
                },
                2: {
                    label: "拒绝退款",
                    value: 2,
                    type: "danger"
                }
            }
        },
        scenic_type() {
            //1.南昌周边亲子游2.游点不一样
            return {
                1: {
                    label: "南昌周边亲子游",
                    value: 1,
                    type: "primary"
                },
                2: {
                    label: "游点不一样",
                    value: 2,
                    type: "success"
                }
            }
        },
        is_hot() {
            //是否热门爆款1是0否
            return {
                1: {
                    label: "是",
                    value: 1,
                    type: "success"
                },
                0: {
                    label: "否",
                    value: 0,
                    type: "primary"
                }
            }
        }
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        logout(state) {
            state.token = "";
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
			 state.store_uid = payload.store_uid;
        },
        setButtons(state, payload) {
            state.buttons = payload;
        },
        setKeepAlive(state, alive) {
            state.keepAlive = alive
        },
        setMenu(state, menu) {
            state.menu = menu
        }
    },
    actions: {
        setKeepAlive(ctx, keepalice) {
            ctx.commit('setKeepAlive', keepalice)
        }
    },
    modules: {
    },
    plugins: [
        createPersistedState()
    ]
})
